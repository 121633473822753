import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";
import { CacheProvider, Global, css } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import "vazir-font/dist/Farsi-Digits/font-face-FD.css";
import { Default } from "@/Components/Layouts";
import { Home, Products, ProductCreate } from "@/Pages";
import { theme } from "@/lib";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
});

const RTL: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

function App() {
  return (
    <>
      <Global
        styles={css`
          body {
            overflow-x: hidden;
            background-color: ${blueGrey[50]};
          }
        `}
      />

      <ThemeProvider theme={theme}>
        <RTL>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Default />}>
                <Route index element={<Home />} />
                <Route path="products">
                  <Route index element={<Products />} />
                  <Route path="create" element={<ProductCreate />} />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </RTL>
      </ThemeProvider>
    </>
  );
}

export default App;
