import { Box, Chip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import type { ProductFilters } from "@/types/product";

type Props = {
  filters: ProductFilters;
  onDelete: (key: string, index: number) => void;
};

const SelectedFilters: React.FC<Props> = ({ filters, onDelete }) => {
  const isEmpty = () => {
    return Object.keys(filters).every(
      (key) => filters[key].values.length === 0
    );
  };

  return (
    <Box display="flex" sx={{ px: 2, py: 3 }} flexWrap="wrap">
      {Object.entries(filters)
        .sort()
        .map(([key, { label, values }]) => {
          return values.map((value, index) => (
            <Box key={index} sx={{ mr: 1, mb: 1 }}>
              <Chip
                label={`${label}: ${value}`}
                onDelete={() => onDelete(key, index)}
              />
            </Box>
          ));
        })}

      {isEmpty() && (
        <Typography variant="body2" color={grey[600]}>
          فیلتری اعمال نشده است
        </Typography>
      )}
    </Box>
  );
};

export default SelectedFilters;
