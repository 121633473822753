import { useEffect } from "react";
import { useLocation, Location } from "react-router-dom";

function useLocationChange(action: (location: Location) => void) {
  const location = useLocation();
  useEffect(() => {
    action(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}

export default useLocationChange;
