import { Box } from "@mui/material";
import FilterBar from "./FilterBar";

const FiltersBar: React.FC = () => {
  const categories = [
    {
      label: "موبایل",
      value: "mobile",
    },
    {
      label: "لپ تاپ",
      value: "laptop",
    },
    {
      label: "کنسول",
      value: "console",
    },
    {
      label: "مانیتور",
      value: "monitor",
    },
  ];
  const statuses = [
    {
      label: "منتشر شده",
      value: "published",
    },
    {
      label: "پیش نویس",
      value: "draft",
    },
  ];
  const stocks = [
    {
      label: "موجود",
      value: "in-stock",
    },
    {
      label: "ناموجود",
      value: "out-of-stock",
    },
  ];

  return (
    <Box display="flex" sx={{ p: 2 }} flexWrap="wrap">
      <FilterBar label="دسته بندی" items={categories} />
      <FilterBar label="وضعیت" items={statuses} />
      <FilterBar label="موجودی" items={stocks} />
    </Box>
  );
};

export default FiltersBar;
