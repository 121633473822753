import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  TableContainer,
} from "@mui/material";
import { Box } from "@mui/system";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";

const ProductListTable: React.FC = () => {
  const rows = [
    {
      name: "Iphone 13 Pro Max",
      category: "mobile",
      status: "published",
      stock: "in-stock",
      price: "40,000,000",
    },
    {
      name: "Macbook Pro M1 2020",
      category: "laptop",
      status: "draft",
      stock: "in-stock",
      price: "37,000,000",
    },
    {
      name: "Xiaomi 34",
      category: "monitor",
      status: "published",
      stock: "out-of-stock",
      price: "12,000,000",
    },
    {
      name: "Iphone 13 Pro Max",
      category: "mobile",
      status: "published",
      stock: "in-stock",
      price: "40,000,000",
    },
    {
      name: "Macbook Pro M1 2020",
      category: "laptop",
      status: "draft",
      stock: "in-stock",
      price: "37,000,000",
    },
    {
      name: "Xiaomi 34",
      category: "monitor",
      status: "published",
      stock: "out-of-stock",
      price: "12,000,000",
    },
    {
      name: "Iphone 13 Pro Max",
      category: "mobile",
      status: "published",
      stock: "in-stock",
      price: "40,000,000",
    },
    {
      name: "Macbook Pro M1 2020",
      category: "laptop",
      status: "draft",
      stock: "in-stock",
      price: "37,000,000",
    },
    {
      name: "Xiaomi 34",
      category: "monitor",
      status: "published",
      stock: "out-of-stock",
      price: "12,000,000",
    },
    {
      name: "Macbook Pro M1 2020",
      category: "laptop",
      status: "draft",
      stock: "in-stock",
      price: "37,000,000",
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>عنوان</TableCell>
            <TableCell>وضعیت</TableCell>
            <TableCell>موجودی</TableCell>
            <TableCell>قیمت</TableCell>
            <TableCell align="center">عملیات</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    minWidth: {
                      xs: "250px",
                      md: "unset",
                    },
                  }}
                >
                  <Box marginRight={2}>
                    <img
                      style={{ borderRadius: "8px" }}
                      width={80}
                      height={80}
                      src="https://material-kit-pro-react.devias.io/static/mock-images/products/product_1.png"
                      alt={row.name}
                      loading="lazy"
                    />
                  </Box>
                  <Box>
                    <Typography fontWeight="500" fontSize={14}>
                      {row.name}
                    </Typography>
                    <Typography fontSize={14}>{row.category}</Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.stock}</TableCell>
              <TableCell>{row.price} تومان</TableCell>
              <TableCell align="center">
                <Box display="flex" justifyContent="center">
                  <IconButton color="info">
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton color="error">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductListTable;
