import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const theme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: ["Vazir FD", "san-serif"].join(","),
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: grey[100],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-child td, &:last-child th": { border: 0 },
        },
      },
      defaultProps: {
        hover: true,
      },
    },
  },
});

export default theme;
