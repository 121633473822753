import { useState } from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import useLocationChange from "@/hooks/useLocationChange";

const Default: React.FC = () => {
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = useState(false);

  useLocationChange(() => {
    if (mobileOpen) setMobileOpen(false);
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      sx={{
        display: {
          sm: "flex",
        },
      }}
    >
      <CssBaseline />

      <Navbar width={drawerWidth} handleToggle={() => handleDrawerToggle()} />

      <Sidebar
        width={drawerWidth}
        isOpen={mobileOpen}
        handleToggle={() => handleDrawerToggle()}
      />

      <Box
        component="main"
        sx={{
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <Outlet />
      </Box>
    </Box>
  );
};

export default Default;
