import { Link } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";

const ProductListToolbar = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 3 }}
    >
      <Typography component="h2" variant="h4" fontWeight="bold">
        محصولات
      </Typography>

      <Box display="flex" alignItems="center">
        <Button
          startIcon={<AddIcon />}
          component={Link}
          to="/products/create"
          variant="contained"
        >
          محصول جدید
        </Button>
      </Box>
    </Box>
  );
};

export default ProductListToolbar;
