import { Card } from "@mui/material";
import usePageTitle from "@/hooks/usePageTitle";
import {
  ProductListToolbar,
  ProductListFilter,
  ProductListTable,
} from "@/Components/Pages";

const Products: React.FC = () => {
  usePageTitle("محصولات");

  return (
    <>
      <ProductListToolbar />

      <Card>
        <ProductListFilter />

        <ProductListTable />
      </Card>
    </>
  );
};

export default Products;
