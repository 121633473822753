import { Box, InputBase } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

type Props = {
  placeholder: string;
  value: string | number;
  onChange: (value: string) => void;
};

const SearchForm: React.FC<Props> = ({ placeholder, onChange, value }) => {
  return (
    <Box sx={{ display: "flex", p: 2 }}>
      <SearchIcon sx={{ color: "action.active", mr: 3, my: 0.5 }} />
      <InputBase
        size="medium"
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder }}
        fullWidth
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
    </Box>
  );
};

export default SearchForm;
