import { useState } from "react";
import { Box, Divider } from "@mui/material";
import type { ProductFilters } from "@/types/product";
import { SearchForm } from "@/Components/Shared";
import SelectedFilters from "./SelectedFilters";
import FiltersBar from "./FiltersBar";

const ProductListFilter: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<ProductFilters>({
    category: {
      label: "دسته بندی",
      values: ["لپ تاپ", "موبایل", "کنسول"],
    },
    status: { label: "وضعیت", values: ["منتشر شده", "پیش نویس"] },
    stock: { label: "موجودی", values: ["موجود", "ناموجود"] },
  });

  const handleDelete = (key: string, index: number) => {
    setFilters((prevState) => {
      const { [key]: filter, ...rest } = prevState;
      const values = filter.values.filter((_, i) => i !== index);
      return { ...rest, [key]: { ...filter, values } };
    });
  };

  return (
    <Box component="form" noValidate autoComplete="false">
      <SearchForm
        value={search}
        onChange={(value) => setSearch(value)}
        placeholder="جستجو بر اساس نام محصول"
      />

      <Divider />

      <SelectedFilters
        filters={filters}
        onDelete={(key, index) => handleDelete(key, index)}
      />

      <Divider />

      <FiltersBar />
    </Box>
  );
};

export default ProductListFilter;
