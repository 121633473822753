import { useMemo, forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useResolvedPath,
  useMatch,
} from "react-router-dom";
import {
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Typography,
} from "@mui/material";
import {
  Home as HomeIcon,
  Store as StoreIcon,
  Inventory as InventoryIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";

const ListItemLink: React.FC<{
  icon?: React.ReactElement;
  primary: string;
  to: string;
}> = ({ icon, primary, to }) => {
  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(function Link(
        itemProps,
        ref
      ) {
        return <RouterLink to={to} ref={ref} {...itemProps} />;
      }),
    [to]
  );

  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li>
      <ListItem selected={!!match} button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

type Props = {
  isOpen: boolean;
  width: number;
  handleToggle: () => void;
};

const Sidebar: React.FC<Props> = ({ width, isOpen, handleToggle }) => {
  const list: {
    title: string;
    href: string;
    icon: React.ReactElement;
  }[] = [
    {
      title: "صفحه اصلی",
      href: "/",
      icon: <HomeIcon />,
    },
    {
      title: "محصولات",
      href: "/products",
      icon: <StoreIcon />,
    },
    {
      title: "سفارشات",
      href: "/orders",
      icon: <InventoryIcon />,
    },
    {
      title: "تراکنش ها",
      href: "/transactions",
      icon: <ReceiptIcon />,
    },
  ];

  const drawer = (
    <aside>
      <Toolbar>
        <Typography
          fontWeight="normal"
          margin="0 auto"
          variant="h6"
          component="h1"
        >
          پنل مدیریت فروشگاه
        </Typography>
      </Toolbar>
      <List>
        {list.map(({ title, href, icon }, index) => (
          <ListItemLink key={index} to={href} primary={title} icon={icon} />
        ))}
      </List>
    </aside>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: width }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={handleToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: width,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: width,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
