import usePageTitle from "@/hooks/usePageTitle";

const CreateProduct = () => {
  usePageTitle("ثبت محصول");

  return (
    <div>
      <h1>Create products</h1>
    </div>
  );
};

export default CreateProduct;
