import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
} from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";

type Props = {
  label: string;
  items: { label: string; value: string }[];
};

const FilterBar: React.FC<Props> = ({ label, items }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ mr: 2 }}>
      <Button onClick={handleClick} endIcon={<KeyboardArrowDownIcon />}>
        {label}
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map(({ label, value }, index) => (
          <MenuItem key={index}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox value={value} defaultChecked />}
                label={label}
              />
            </FormGroup>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default FilterBar;
